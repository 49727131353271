(function($) {
    'use strict';

    $('#wallpaper-calculation-form').on('submit', function(e){
        e.preventDefault();
        e.stopPropagation();

        var data = {};
        $(this).find('[name]').each(function(index, value){
            var that = $(this);
            var name = that.attr('name');
            data[name] = that.val();
        });

        var width = parseFloat(data.width);
        var height = parseFloat(data.height);
        var ceiling_height = parseFloat(data.ceiling_height);
        var wall_length = parseFloat(data.wall_length);
        var pattern = parseInt(data.pattern);

        var units = wall_length / width;
        parseInt(units);
        units = Math.ceil(units);
        var unitsPerRoll;

        if (pattern > 0) {
            unitsPerRoll = height / (ceiling_height + (pattern / 2));
            unitsPerRoll = Math.floor(unitsPerRoll);
        } else {
            unitsPerRoll = height / ceiling_height;
            unitsPerRoll = Math.floor(unitsPerRoll);
        }
        var antal = units / unitsPerRoll;
        var count = Math.ceil(antal);
        $("#result").empty().html("Du behöver köpa minst <strong>" + count + "</strong> rullar");

        return false;
    });

    $("#submit-wallpaper-calculation").click(function(){
        $("#wallpaper-calculation-form").submit();
    });

})(jQuery);