/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                // Fungerar bara på första sidinladdning, fixa!
                $('a.page-numbers').live('click', function(e) {
                    $("html, body").scrollTop(0);
                });
                            
                $(window).scroll(function() {
                    var p = $(window).scrollTop();
                    if (p <= 10) {
                        $('.mobile-header').removeClass('scroll');
                        $('.main-header').removeClass('scroll');
                    } else {
                        $('.main-header').addClass('scroll');
                        $('.mobile-header').addClass('scroll');
                    }
                });

                // Scroll animations
                function getBrowser(){
                    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
                    if(/trident/i.test(M[1])){
                        tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
                        return {name:'IE',version:(tem[1]||'')};
                        }   
                    if(M[1]==='Chrome'){
                        tem=ua.match(/\bOPR|Edge\/(\d+)/);
                        if(tem!==null)   {return {name:'Opera', version:tem[1]};}
                    }   
                    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
                    if((tem=ua.match(/version\/(\d+)/i))!==null) {M.splice(1,1,tem[1]);}
                    return {
                        name: M[0],
                        version: M[1]
                    };
                }

                var browser = getBrowser();
                if (browser.name === "Safari" && browser.version >= 11) {
                    var wow = new WOW({
                      boxClass:     'wow',
                      animateClass: 'animated',
                      offset:       0,
                      mobile:       true,
                      live:         true,
                      callback:     function(box) {},
                      scrollContainer: null
                    });
                    wow.init();
                }

                if (browser.name === "IE") {
                    $('body').addClass("IE");
                    $('.IE-warning').show();
                }

                /* Mobile menu hover fix */
                $('.mobile-menu-modal .navbar-nav .dropdown').append('<span class="nav-arrow"></span>');
                $('.mobile-menu-modal .navbar-nav .nav-arrow').click(function() {
                    $(this).toggleClass('open');
                    $(this).closest('li').find('ul').first().slideToggle();
                });

                // Intro slider
                $('.intro-slider').slick({
                    dots: true,
                    infinite: true,
                    speed: 300,
                    arrows: true,
                    prevArrow: '<div data-role="none" class="slick-slide-prev"></div>',
                    nextArrow: '<div data-role="none" class="slick-slide-next"></div>',
                    autoplay: false,
                    touchMove: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                });

                /* logo carousel */
                $('.logo-carousel').slick({
                    dots: false,
                    infinite: true,
                    speed: 300,
                    arrows: true,
                    asNavFor: null,
                    prevArrow: '<div data-role="none" class="slick-slide-prev"></div>',
                    nextArrow: '<div data-role="none" class="slick-slide-next"></div>',
                    cssEase: 'ease',
                    easing: 'linear',
                    centerMode: false,
                    autoplay: true,
                    touchMove: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    responsive: [
                    {
                      breakpoint: 880,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        dots: false,
                        infinite: true,
                      }
                    },
                    {
                      breakpoint: 680,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        dots: false,
                        infinite: true,
                      }
                    },
                    {
                      breakpoint: 420,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                        infinite: true,
                      }
                    }
                    ]
                });
                var arrows = '<div class="arrows"><div data-role="none" class="slick-slide-prev slick-arrow"></div><div data-role="none" class="slick-slide-next slick-arrow"></div></div>';
                $('.logo-slider').append(arrows);
                $('.slick-slide-prev').click(function() {
                    $(".logo-carousel").slick('slickPrev');
                });
                $('.slick-slide-next').click(function() {
                    $(".logo-carousel").slick('slickNext');
                });

                // Add fadeToggle animation to dropdown       
                $('.dropdown').on('show.bs.dropdown', function(e) {
                    $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
                    $('.search-button').closest('.input-group').removeClass('open');
                    $('.search-field').focus();
                });

                // Add fadeToggle animation to dropdown 
                $('.dropdown').on('hide.bs.dropdown', function(e) {
                    if ($(this).hasClass('slide-toggle')) {
                        $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
                    } else {
                        $(this).find('.dropdown-menu').first().stop(true, true).hide();
                    }
                });

                // Checkbuttons & Radiobuttons
                $("body").on("click", "input[type='checkbox']", function() {
                    $(this).toggleClass("active");
                });
                $("input[type='checkbox']:checked").addClass("active");
                $("body").on("click", "input[type='radio']", function() {
                    $(this).toggleClass("active");
                });
                $("input[type='radio']:checked").addClass("active");

                // Tooltips
                $('[data-toggle="tooltip"]').tooltip();

                // Popover
                $('[data-toggle="popover"]').popover();

                // Radio
                $(".wrapper").on("click", "input[type='radio']", function() {
                    $("input[name*=" + $(this).attr('name') + "]").not($(this)).removeClass('active');
                    $(this).toggleClass("active");
                });

                // Search buttons
                $('.search-button').on('click', function() {
                    $(this).closest('.input-group').toggleClass('open');
                    $(this).closest('.input-group').find('.search-field').focus();
                });         

                // Tilt
                VanillaTilt.init(document.querySelectorAll(".js-tilt"), {
                    max: 25,
                    speed: 300,
                    reverse: true,
                    perspective: 300
                });

                $('.navbar-toggler').on('click', function() {
                    $(this).addClass('active');
                    $('#mobileMenuModal').modal('show');
                });

                $('#mobileMenuModal').on('hide.bs.modal', function (e) {
                    $('.navbar-toggler').removeClass('active');
                });

                // Sidebar filter slide down
                $('.sidebar-header').on('click', function() {
                    var sidebar = $(this).closest('.sidebar');
                    sidebar.find('.sidebar-body').slideToggle(300);
                    $(this).find('.sidebar-header-icon').toggleClass('active');

                    setTimeout( function(){
                        sidebar.toggleClass('open fixed');
                    }, 300);
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.