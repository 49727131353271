(function($) {
    'use strict';

    $('#my-account-menu-tab .myaccount-menu').scroll(function () {
        var newScrollLeft = $(this).scrollLeft(),
            width = $(this).width(),
            scrollWidth = $(this).get(0).scrollWidth;
        var offset = 15;
        if (scrollWidth - newScrollLeft - width <= offset) {
            $('#my-account-menu-tab').addClass('right-end');
        }else{
            $('#my-account-menu-tab').removeClass('right-end');
        }
        if (newScrollLeft <= offset) {
            $('#my-account-menu-tab').addClass('left-end');
        }else{
            $('#my-account-menu-tab').removeClass('left-end');
        }
    });

    function get_list_items_width(element){
        var list_items_width = 0;
        element.find("ul > li").each(function() {
            list_items_width += $(this).width();
        });
        return list_items_width;
    }

    function overflow_effect(element){
        element.find('ul').first().scroll(function () {
            var newScrollLeft = $(this).scrollLeft(),
                width = $(this).width(),
                scrollWidth = $(this).get(0).scrollWidth;
            var offset = 15;
            if (scrollWidth - newScrollLeft - width <= offset) {
                element.addClass('right-end');
            }else{
                element.removeClass('right-end');
            }
            if (newScrollLeft <= offset) {
                element.addClass('left-end');
            }else{
                element.removeClass('left-end');
            }
        });
    }

    overflow_effect($('#my-account-menu-tab'));

    function resize_overflow_menu(element){
        if( get_list_items_width(element) + 20 >= element.width()){
            element.addClass('scroll');
        }else{
            element.removeClass('scroll');
        }
    }

    resize_overflow_menu($('#my-account-menu-tab')); 
    $(window).resize(function() {
        resize_overflow_menu($('#my-account-menu-tab'));
    });

})(jQuery);