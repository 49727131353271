(function($) {
    'use strict';

    function productAddedToCart(count){
        var desktop_cart = $('.main-header .dropdown-cart');
        var mobile_cart = $('.mobile-header-content .dropdown-cart');

        desktop_cart.find('.badge').text(count);
        mobile_cart.find('.badge').text(count);
        desktop_cart.find('.badge').removeClass('empty');
        mobile_cart.find('.badge').removeClass('empty');
    }
    function productRemovedFromCart(count){
        var desktop_cart = $('.main-header .dropdown-cart');
        var mobile_cart = $('.mobile-header-content .dropdown-cart');

        if(count <= 0){
            desktop_cart.find('.badge').text(count);
            mobile_cart.find('.badge').text(count);
            desktop_cart.find('.badge').addClass('empty');
            mobile_cart.find('.badge').addClass('empty');
        }
    }

    function addProductToCart(type, mini_cart_spinner, mini_cart_content){
        var data = {};
        $('.ajax-cart').find('[name]').each(function(index, value){
            var that = $(this);
            var name = that.attr('name');

            var values;
            if(that.is('select')){
                values = {
                    value: that.val(), 
                    label: that.find("option:selected").text()
                };
            }else{
                values = that.val();
            }
            
            data[name] = values;
        });

        data.type = type;

        var data_attr = {
            action: "update_mini_cart",
            data: data,
            mode: "add"
        };  

        $.ajax({
            url: variables.ajax_url,
            data: data_attr,
            type: 'post',
            dataType: 'json',
            beforeSend: function(){
                mini_cart_spinner.show();
                mini_cart_content.hide();
                if ($(window).width() > 768){
                    $('.main-header .dropdown-cart').dropdown('toggle');
                }else{
                    $('.mobile-header-content .dropdown-cart').dropdown('toggle');
                }
            },
            success: function(data, textStatus, XMLHttpRequest) {
                if(data.status === 200){
                    $('.mini-cart-content-output').html(data.content);
                    window.site.events.publish('event.productAddedToCart', data.count);
                    mini_cart_content.show();
                }else{
                    mini_cart_content.show();
                    $('.notice-modal .modal-header .modal-title').text(data.title);
                    $('.notice-modal .modal-body').html("<p>"+ data.message +"</p>");
                    $('#noticeModal').modal('show');
                }

                mini_cart_spinner.hide();
            },
            error: function(MLHttpRequest, textStatus, errorThrown) {
                /*console.log(MLHttpRequest);
                console.log(textStatus);
                console.log(errorThrown);*/
            }
        });
    }

    window.site.events.subscribe('event.productAddedToCart', productAddedToCart);
    window.site.events.subscribe('event.productRemovedFromCart', productRemovedFromCart);

    var mini_cart_spinner = $('.mini-cart-spinner');
    var mini_cart_content = $('.mini-cart-content');

    $('#add-to-cart, #add-sample-to-cart').click(function(e){
        e.preventDefault();
        e.stopPropagation();

        var type = $(this).data('type');
        addProductToCart(type, mini_cart_spinner, mini_cart_content);

        return false;
    });

    $('.mini-cart').on("click", ".remove-from-cart", function(e){
        e.preventDefault();
        e.stopPropagation();
        
        var media = $(this).closest(".media");
        var data = {};
        data.cart_item_key = media.find(".remove-from-cart").data("cart-item-key");

        var data_attr = {
            action: "update_mini_cart",
            data: data,
            mode: "delete"
        };
        
        $.ajax({
            url: variables.ajax_url,
            data: data_attr,
            type: 'post',
            dataType: 'json',
            success: function(data, textStatus, XMLHttpRequest) {
                if(data.status === 200){
                    media.remove();
                    $('.mini-cart-content-output').html(data.content);
                    window.site.events.publish('event.productRemovedFromCart', data.count);
                }
            },
            error: function(MLHttpRequest, textStatus, errorThrown) {
                /*console.log(MLHttpRequest);
                console.log(textStatus);
                console.log(errorThrown);*/
            }
        });

        return false;
    });

    // Load the mini cart after page load
    $.ajax({
        url: variables.ajax_url,
        data: {
            action: "load_mini_cart"
        },
        type: 'post',
        beforeSend: function(){
            mini_cart_spinner.show();
            mini_cart_content.fadeOut();
        },
        success: function(data, textStatus, XMLHttpRequest) {
            $('.mini-cart-content-output').html(data);
            mini_cart_content.fadeIn();
            mini_cart_spinner.hide();
        },
        error: function(MLHttpRequest, textStatus, errorThrown) {
            /*console.log(MLHttpRequest);
            console.log(textStatus);
            console.log(errorThrown);*/
        }
    });

})(jQuery);