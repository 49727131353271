(function($) {
    'use strict';

    var storelocator_content = $("#storelocator-content").html();
    $('#wpsl-result-list-container').prepend("<div class='wpsl-content-box'>"+ storelocator_content +"</div>");
    $("#wpsl-search-wrap #wpsl-search-input").attr("placeholder", "Ange plats eller postkod");

    $(window).load(function(){
        $('#wpsl-wrap').addClass('show');
    });

})(jQuery);