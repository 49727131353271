(function($) {
    'use strict';

    function wishlistChange(count){
        var desktop_wishlist = $('.main-header .heart');
        var mobile_wishlist = $('.mobile-header-content .heart');

        desktop_wishlist.find('.badge').text(count);
        mobile_wishlist.find('.badge').text(count);

        if(count > 0){
            desktop_wishlist.find('.badge').removeClass('empty');
            mobile_wishlist.find('.badge').removeClass('empty');
        }else{
            desktop_wishlist.find('.badge').addClass('empty');
            mobile_wishlist.find('.badge').addClass('empty');
        }
    }

    window.site.events.subscribe('event.wishlistChange', wishlistChange);

    // Wishlist
    $('.wishlist-toggle').click(function(e){
        e.preventDefault();
        e.stopPropagation();

        var wishlist_overlay = $(this).closest('.wishlist-overlay');
        var product_id = $(this).data("id");
        var mode = $(this).data("mode");
        var current_count = parseInt($('.main-header .heart .badge').text());

        if(!$(this).hasClass("active")){
            wishlist_overlay.addClass("active");
            $(this).addClass("active");
            
            setTimeout( function(){
                wishlist_overlay.find('.product-removed').hide();
                wishlist_overlay.find('.product-added').show();
            }, 400);
            
            $.ajax({
                url: "/?add_to_wishlist="+product_id,
                type: 'get',
                beforeSend: function(){
                    var count = current_count + 1;
                    window.site.events.publish('event.wishlistChange', count);
                }
            });
        }else{
            wishlist_overlay.removeClass("active");
            $(this).removeClass("active");

            setTimeout( function(){
                wishlist_overlay.find('.product-added').hide();
                wishlist_overlay.find('.product-removed').show();
                if(mode === "page"){
                    wishlist_overlay.closest(".product").fadeOut();
                }
            }, 400);

            $.ajax({
                url: "/?remove_from_wishlist="+product_id,
                type: 'get',
                beforeSend: function(){
                },
                success: function(){
                    var count = current_count - 1;
                    window.site.events.publish('event.wishlistChange', count);
                    
                    if(mode === "page"){
                        if(count < 1){
                            $('.wishlist-empty').removeClass('hidden-xs-up');
                        }else if(count >= 1){
                            $('.wishlist-empty').addClass('hidden-xs-up');
                        }
                    }
                }
            });
        }

        return false;
    });

    $(".product").each(function() {
        if($(this).find('.yith-wcwl-wishlistexistsbrowse').hasClass('show')){
            $(this).find('.wishlist-overlay').addClass('active');
            $(this).find('.wishlist-toggle').addClass('active');
        }
    });

})(jQuery);